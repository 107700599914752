import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useGetOCSActivity } from 'cb-wallet-data/hooks/Gamification/useGetOCSActivity';
import { usePublicProfileByAddresses } from 'cb-wallet-data/stores/DecentralizedID/hooks/usePublicProfileByAddresses';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';
import { OCSActivityItem } from './OCSActivityItem';
const activityFeedCss = "ap65ugt";
const messages = defineMessages({
  noActivity: {
    defaultMessage: 'No recent activity',
    description: 'Message for no activity'
  }
});
export function OCSActivityFeed() {
  const {
    formatMessage
  } = useIntl();
  const {
    activity,
    isLoading
  } = useGetOCSActivity();
  const {
    profiles
  } = usePublicProfileByAddresses({
    addresses: activity?.length ? [...activity.map(act => act.userAddress)] : []
  });
  const userProfiles = useMemo(() => {
    const profileObj: Record<string, PublicProfile> = {};
    if (!profiles) return profileObj;
    profiles?.forEach(profile => {
      profileObj[profile.address] = profile;
    });
    return profileObj;
  }, [profiles]);
  const getUserProfileName = useCallback((userAddress: string) => {
    return userProfiles[userAddress]?.primaryDomainType === 'eth' ? userProfiles[userAddress]?.ensDomainProfile?.name : userProfiles[userAddress]?.subdomainProfile?.name;
  }, [userProfiles]);
  const memoizedActivityItems = useMemo(() => {
    return activity?.map((singleActivity, index) => <OCSActivityItem
    // eslint-disable-next-line react/no-array-index-key
    key={`ocs_activity_${index}`} name={getUserProfileName(singleActivity.userAddress)} userAddress={singleActivity.userAddress} userProfile={userProfiles[singleActivity.userAddress]} activityData={singleActivity} />);
  }, [activity, userProfiles, getUserProfileName]);
  if (isLoading) {
    return <OCSActivityFeedLoading />;
  }
  return <VStack className={activityFeedCss} flexBasis={0} flexGrow={1} flexShrink={1} overflow="scroll" width="100%" height={410} testID="ocs-activity-feed">
      {memoizedActivityItems}
      {!activity || activity?.length === 0 && <Box justifyContent="center" alignItems="center" spacingTop={10}>
            <TextHeadline as="h1">{formatMessage(messages.noActivity)}</TextHeadline>
          </Box>}
    </VStack>;
}
function OCSActivityFeedLoading() {
  return <VStack className={activityFeedCss} spacingHorizontal={2} spacingVertical={2} flexBasis={0} flexGrow={1} flexShrink={1} testID="ocs-activity-feed-loading">
      {[...Array(6)].map((_, index) =>
    // eslint-disable-next-line react/no-array-index-key
    <VStack gap={2} key={`ocs_activity_loader_${index}`}>
          <HStack gap={0.5} />
          <Box width="100%" height={38} dangerouslySetBackground="grey" />
        </VStack>)}
    </VStack>;
}

require("./OCSActivityFeed.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSActivityFeed.tsx");