import { CSSProperties, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'wallet-cds-web/components/Button';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextLabel2, TextLegal, TextTitle1 } from '@cbhq/cds-web/typography';

import { OCSBitcoinGlobe } from './OCSBitcoinGlobe';

const messages = defineMessages({
  title: {
    defaultMessage: 'Enter for a shot at 1 BTC',
    description: 'title',
  },
  subtitle: {
    defaultMessage:
      'Join the Onchain Summer Giveaway for a chance to win. Coinbase Wallet exclusive.',
    description: 'subtitle',
  },
  legal: {
    defaultMessage:
      'No purchase necessary. Limited to US (excl. HI), CA (excl. QC), and IE. See rules.',
    description: 'legal',
  },
  button: {
    defaultMessage: 'Opt in',
    description: 'call to action',
  },
});
const gradientStyle: CSSProperties = {
  background: 'linear-gradient(to right, #fca4f4, #fcfc05)', // Change colors as needed
};

export function OCSSweepstakesBanner() {
  const { isDesktop, isTabletLandscape } = useBreakpoints();
  const { formatMessage } = useIntl();

  const infoDetails = useMemo(
    () => (
      <>
        <VStack gap={1} spacing={3} id="spin-the-wheel-banner" maxWidth={600}>
          <TextTitle1 as="h3" color="secondary">
            {formatMessage(messages.title)}
          </TextTitle1>
          <ThemeProvider spectrum="light">
            <TextLabel2 as="p" color="foregroundMuted">
              {formatMessage(messages.subtitle)}
            </TextLabel2>
          </ThemeProvider>
          <ThemeProvider spectrum="light">
            <TextLegal as="p" color="foregroundMuted">
              {formatMessage(messages.legal)}
            </TextLegal>
          </ThemeProvider>
          <Spacer vertical={1} />
          <Box width="200px" zIndex={1}>
            <ThemeProvider spectrum="dark">
              <Button variant="secondary" testID="spinBtn" href="/ocs/giveaway" compact>
                {formatMessage(messages.button)}
              </Button>
            </ThemeProvider>
          </Box>
        </VStack>
        {(isDesktop || isTabletLandscape) && (
          <Box position="absolute" right={40} top={5}>
            <OCSBitcoinGlobe height={200} />
          </Box>
        )}
      </>
    ),
    [isDesktop, isTabletLandscape, formatMessage],
  );

  return (
    <>
      {isTabletLandscape || isDesktop ? (
        <HStack
          style={gradientStyle}
          background="positive"
          justifyContent="space-between"
          overflow="hidden"
          position="relative"
          borderRadius="roundedLarge"
        >
          {infoDetails}
        </HStack>
      ) : (
        <VStack
          style={gradientStyle}
          overflow="hidden"
          gap={3}
          borderRadius="roundedLarge"
          alignContent="center"
          justifyContent="center"
          alignSelf="center"
        >
          {infoDetails}
          <Box position="relative" height={155} alignSelf="center">
            <Box position="absolute" left={-100} top={-60}>
              <OCSBitcoinGlobe />
            </Box>
          </Box>
        </VStack>
      )}
    </>
  );
}
