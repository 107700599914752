import { defineMessages, useIntl } from 'react-intl';
import { OCSOnboardingLeaderboard } from 'cb-wallet-data/hooks/Gamification/useGetOCSOnboardingLeaderboard';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media';
import { TextBody, TextHeadline, TextLabel1 } from '@cbhq/cds-web/typography';

import { useIsMobile } from ':dapp/hooks/useIsMobile';

import { OCSSectionHeader } from '../OCSSectionHeader';

const messages = defineMessages({
  tableHeading: {
    defaultMessage: 'community',
    description: 'subheading denoting token in the table',
  },
  tablePoints: {
    defaultMessage: 'users onboarded',
    description: 'subheading denoting total users onboarded in the table',
  },
  tableTitle: {
    defaultMessage: 'Onboarding leaderboard',
    description: 'title of the leaderboard table',
  },
  tableSubheading: {
    defaultMessage:
      'See the communities bringing people onchain, sending crypto over text using Coinbase Wallet. Crypto must be claimed to count as a user onboarded.',
    description: 'subheading of the leaderboard table',
  },
});

type LeaderboardSectionProps = {
  leaderboard: OCSOnboardingLeaderboard[] | undefined;
  isLoading: boolean;
};

export function LeaderboardSection({ isLoading, leaderboard }: LeaderboardSectionProps) {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  return (
    <>
      <OCSSectionHeader
        title={formatMessage(messages.tableTitle)}
        subtitle={formatMessage(messages.tableSubheading)}
        spacingBottom={4}
      />
      <HStack justifyContent="space-between" testID="ocs-onboarding-leaderboard-heading">
        <TextHeadline as="h2"> {formatMessage(messages.tableHeading).toUpperCase()}</TextHeadline>
        {isMobile ? null : (
          <TextHeadline as="h2"> {formatMessage(messages.tablePoints).toUpperCase()}</TextHeadline>
        )}
      </HStack>
      <VStack gap={3} spacingVertical={1} />
      <Leaderboard leaderboard={leaderboard} isLoading={isLoading} />
    </>
  );
}

type LeaderboardProps = {
  leaderboard?: OCSOnboardingLeaderboard[];
  isLoading: boolean;
};

function Leaderboard({ leaderboard, isLoading }: LeaderboardProps) {
  const { formatNumber } = useIntl();
  if (isLoading) {
    return (
      <>
        {[...Array(10)].map((_, index) => (
          <Box
            width="100%"
            height={82}
            background="secondary"
            borderRadius="roundedXLarge"
            testID="onboarding-leaderboard-loading"
            // eslint-disable-next-line react/no-array-index-key
            key={`onboarding-leaderboard-loading-${index}`}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {leaderboard?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`onboarding-leaderboard-item-${index}`} spacingTop={1} width="100%">
          <Box
            dangerouslySetBackground="#1E2025"
            width="100%"
            borderRadius="roundedMedium"
            spacingVertical={1}
            spacingHorizontal={3}
          >
            <HStack justifyContent="space-between" width="100%" alignItems="center">
              <HStack gap={2} alignItems="center">
                <TextBody as="span" noWrap color="foreground">
                  {index + 1}
                </TextBody>
                <Box borderRadius="roundedLarge" overflow="hidden" height={50} width={50}>
                  <RemoteImage
                    alt="thumbnail"
                    source={item.logo}
                    shape="circle"
                    resizeMode="cover"
                    width="100%"
                    height="100%"
                  />
                </Box>
                <TextHeadline as="h2" noWrap>
                  {item.symbol}
                </TextHeadline>
              </HStack>
              <TextLabel1 as="span" noWrap>
                {formatNumber(item.numOnboarded)}
              </TextLabel1>
            </HStack>
          </Box>
        </Box>
      ))}
    </>
  );
}
