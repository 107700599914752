import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { GAMIFICATION_URL } from './config';

export type OCSOnboardingLeaderboard = {
  symbol: string;
  logo: string;
  numOnboarded: number;
};

export function useGetOCSOnboardingLeaderboard() {
  const fetchQuery = async () => {
    const response = await fetch(`${GAMIFICATION_URL}/onboarding-leaderboard`);
    return response.json();
  };

  const { data, isError, isLoading, error } = useQuery<OCSOnboardingLeaderboard[], Error>(
    ['game_getOnboardingLeaderboard'],
    fetchQuery,
    {
      staleTime: 30 * 1000, // 30 seconds
    },
  );

  return { leaderboard: data, isError, isLoading, error };
}
