import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { GetContentUnauthResponse } from '../types/ocsResponseTypes';

export function useOCSComingSoon(handleError?: (err?: Error) => void) {
  const fetchContent = async () => {
    return getJSON<GetContentUnauthResponse>('explore/getContentByComingSoonUnauth', {
      surface: '1',
    });
  };
  return useQuery({
    queryKey: ['getContentByComingSoonUnauth'],
    queryFn: async () => {
      return fetchContent();
    },
    onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
      if (handleError) {
        handleError(err);
      }
    },
    staleTime: 1000 * 45,
    suspense: false,
  });
}
