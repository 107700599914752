import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';

import { CardWithImage } from ':dapp/components/Card/CardWithImage';
import { ContentComponent } from ':dapp/pages/ocs/types/ocsResponseTypes';

const messages = defineMessages({
  ctaPoints: {
    defaultMessage: '(+{points} points)',
    description:
      'indicator to the user for how many points will be gained by completing this challenge',
  },
});

type TrendingCardProps = {
  card: ContentComponent;
  onClick: () => void;
  shareClick?: (event: React.MouseEvent) => void;
  isLoading: boolean;
  gamificationError?: boolean;
};

export function TrendingCard({
  card,
  onClick,
  shareClick,
  isLoading,
  gamificationError,
}: TrendingCardProps) {
  const { formatMessage } = useIntl();
  const challengeBadgesEnabled = useIsFeatureEnabled('ocs_challenge_badges');
  const content = card.ocsChallengeCard;

  const image = useMemo(() => ({ src: content.imageUrl }), [content]);
  const action = useMemo(
    () => ({
      text: `${content.ctaText}${
        content.points > 0
          ? ` ${formatMessage(messages.ctaPoints, { points: content.points })}`
          : ''
      }`,
      url: content.targetUrl,
    }),
    [content, formatMessage],
  );

  const badge = useMemo(
    () => (challengeBadgesEnabled ? { numCompletions: content.numCompletions } : undefined),
    [content, challengeBadgesEnabled],
  );

  return (
    <CardWithImage
      key={`ocs-play-tab-cards-${content.title}`}
      badge={gamificationError ? undefined : badge}
      isLoading={isLoading}
      hasCompleted={content.hasCompleted === 2}
      title={content.title}
      label={content.creatorName}
      image={image}
      isGradient
      action={action}
      onClick={onClick}
      shareClick={gamificationError ? undefined : shareClick}
    />
  );
}
