import { useQuery } from 'cb-wallet-data/hooks/useQuery';

import { GAME_ID, GAMIFICATION_URL } from './config';

export type OCSActivity = {
  createdAt: string;
  userAddress: string;
  type: 'challenge' | 'referral' | 'badge' | 'spin' | 'general';
  data?: ActivityData;
};

export type ActivityData = {
  badgeId?: number;
};

export async function getActivity(): Promise<OCSActivity[]> {
  const response = await fetch(`${GAMIFICATION_URL}/activity?gameId=${GAME_ID}`);

  return response.json();
}

export function useGetOCSActivity() {
  const fetchQuery = async () => getActivity();
  const { data, isError, isLoading, error } = useQuery<OCSActivity[], Error>(
    ['game_ocsActivity'],
    fetchQuery,
    {
      staleTime: 30 * 1000, // 30 seconds
    },
  );
  return { activity: data, isError, isLoading, error };
}
