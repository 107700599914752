export function OCSCreateIcon() {
  return (
    <svg width="500" viewBox="0 0 377 189" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M373.851 75.3687H304.748C303.016 75.3687 301.6 73.9557 301.6 72.2288V3.30858C301.6 1.58164 303.016 0.168701 304.748 0.168701H373.851C375.583 0.168701 377 1.58164 377 3.30858V72.2288C377 73.9557 375.583 75.3687 373.851 75.3687Z"
        fill="#7FD057"
      />
      <path
        d="M297.927 175.635H217.306C215.286 175.635 213.633 173.987 213.633 171.972V91.5652C213.633 89.5504 215.286 87.902 217.306 87.902H297.927C299.947 87.902 301.599 89.5504 301.599 91.5652V171.972C301.599 173.987 299.947 175.635 297.927 175.635Z"
        fill="#45E1E5"
      />
      <path
        d="M263.899 37.7687H226.199V50.302H263.899V37.7687Z"
        fill="url(#paint0_linear_177_4368)"
      />
      <path
        d="M175.933 150.569H100.533V163.102H175.933V150.569Z"
        fill="url(#paint1_linear_177_4368)"
      />
      <path
        d="M60.2098 188.169H2.62352C1.18058 188.169 0 186.989 0 185.547V128.124C0 126.682 1.18058 125.502 2.62352 125.502H60.2098C61.6527 125.502 62.8333 126.682 62.8333 128.124V185.678C62.8333 187.12 61.6527 188.169 60.2098 188.169Z"
        fill="#CE46BD"
      />
      <path
        d="M195.82 125.502H80.6474C77.7616 125.502 75.4004 123.147 75.4004 120.269V5.40183C75.4004 2.52361 77.7616 0.168701 80.6474 0.168701H195.82C198.706 0.168701 201.067 2.52361 201.067 5.40183V120.269C201.067 123.147 198.706 125.502 195.82 125.502Z"
        fill="#0052FF"
      />
      <path
        d="M257.522 103.569L262.876 118.696L277.508 111.755L270.548 126.526L285.894 131.865L270.548 137.204L277.508 151.976L262.876 145.035L257.522 160.162L251.991 145.035L237.359 151.976L244.318 137.204L229.15 131.865L244.318 126.526L237.359 111.755L251.991 118.696L257.522 103.569Z"
        fill="url(#paint2_linear_177_4368)"
      />
      <path
        d="M257.166 160.518C241.642 160.518 228.973 147.882 228.973 132.399V131.865H285.36V132.399C285.36 147.882 272.869 160.518 257.166 160.518Z"
        fill="url(#paint3_linear_177_4368)"
      />
      <path
        d="M285.358 131.865H229.328L244.317 137.204L237.358 151.976L251.99 145.035L257.522 160.162L262.875 145.035L277.507 151.976L270.548 137.204L285.358 132.043V131.865Z"
        fill="black"
      />
      <path
        d="M43.9829 62.8353C37.0713 62.8353 31.4163 68.4753 31.4163 75.3687C31.4163 68.4753 25.7613 62.8353 18.8496 62.8353C25.7613 62.8353 31.4163 57.1953 31.4163 50.302C31.4163 57.1953 37.0713 62.8353 43.9829 62.8353Z"
        fill="#7FD057"
      />
      <path
        d="M351.866 130.202C344.954 130.202 339.299 135.842 339.299 142.735C339.299 135.842 333.644 130.202 326.732 130.202C333.644 130.202 339.299 124.562 339.299 117.669C339.299 124.562 344.954 130.202 351.866 130.202Z"
        fill="#464B55"
      />
      <path
        d="M52.2969 156.836C52.2969 168.328 42.9482 177.644 31.416 177.644C19.8838 177.644 10.5352 168.328 10.5352 156.836C10.5352 145.344 19.8838 136.028 31.416 136.028C42.9482 136.028 52.2969 145.344 52.2969 156.836Z"
        fill="url(#paint4_linear_177_4368)"
      />
      <path
        d="M31.9247 144.936C38.5198 145.216 43.6385 150.77 43.3578 157.342C43.0771 163.914 37.5032 169.015 30.9082 168.735C24.3131 168.456 19.1944 162.901 19.4751 156.329C19.7558 149.757 25.3297 144.656 31.9247 144.936Z"
        fill="#0052FF"
      />
      <path
        d="M359.292 29.1387C357.719 28.5178 356.224 28.6575 354.978 29.2163V17.3889C354.978 15.7901 353.669 14.4863 352.065 14.4863H326.536C324.932 14.4863 323.624 15.7901 323.624 17.3889V29.2163C322.362 28.6575 320.882 28.5178 319.309 29.1387C317.736 29.7595 316.599 31.0323 316.163 32.5844C315.119 36.263 317.876 39.6002 321.396 39.6002C322.144 39.6002 322.845 39.445 323.484 39.1811C322.596 43.9928 322.72 48.9596 323.795 53.7403C324.153 55.3235 324.621 56.8912 325.493 58.257C326.381 59.6074 327.72 60.7405 329.309 61.0509H349.293C350.881 60.7405 352.237 59.6074 353.109 58.257C353.997 56.9067 354.464 55.3235 354.807 53.7403C355.866 48.9596 355.99 43.9928 355.118 39.1811C355.757 39.445 356.473 39.6002 357.205 39.6002C360.725 39.6002 363.482 36.263 362.439 32.5844C362.003 31.0323 360.803 29.7285 359.292 29.1387Z"
        fill="url(#paint5_linear_177_4368)"
      />
      <path
        d="M326.522 14.4863H352.051C353.655 14.4863 354.963 15.7901 354.963 17.3889V24.3891H323.609V17.3889C323.609 15.7901 324.918 14.4863 326.522 14.4863Z"
        fill="#FFE835"
      />
      <path
        d="M338.635 56.829C333.355 56.1461 329.523 51.412 329.523 46.1036V44.3186C329.523 43.3408 330.318 42.5492 331.299 42.5492H348.713C349.694 42.5492 350.488 43.3408 350.488 44.3186V46.4761C350.488 52.7003 345.006 57.6516 338.62 56.8445L338.635 56.829Z"
        fill="black"
      />
      <path
        d="M340.023 47.5325C336.41 47.5325 333.139 48.9139 330.646 51.149C332.375 54.5637 335.927 56.923 340.023 56.923C344.12 56.923 347.671 54.5637 349.4 51.149C346.923 48.9139 343.637 47.5325 340.023 47.5325Z"
        fill="url(#paint6_linear_177_4368)"
      />
      <path
        d="M331.898 37.0079C333.696 37.0079 335.153 35.5555 335.153 33.7639C335.153 31.9723 333.696 30.5199 331.898 30.5199C330.1 30.5199 328.643 31.9723 328.643 33.7639C328.643 35.5555 330.1 37.0079 331.898 37.0079Z"
        fill="black"
      />
      <path
        d="M346.697 37.007C348.495 37.007 349.952 35.5547 349.952 33.763C349.952 31.9714 348.495 30.519 346.697 30.519C344.899 30.519 343.441 31.9714 343.441 33.763C343.441 35.5547 344.899 37.007 346.697 37.007Z"
        fill="black"
      />
      <path
        d="M349.087 28.9836L342.125 33.6401L340.895 31.731L348.527 28.0679L349.087 28.9836Z"
        fill="black"
      />
      <path
        d="M329.352 28.9836L336.314 33.64L337.529 31.6998L329.99 28.1299L329.352 28.9836Z"
        fill="black"
      />
      <path
        d="M339.936 34.9748L341.696 38.017C341.976 38.4982 341.618 39.1035 341.057 39.1035H337.537C336.976 39.1035 336.618 38.4982 336.898 38.017L338.658 34.9748C338.939 34.4936 339.64 34.4936 339.92 34.9748H339.936Z"
        fill="black"
      />
      <path
        d="M31.4729 150.795C34.4808 150.825 36.8944 153.28 36.8639 156.277C36.8334 159.275 34.3703 161.68 31.3623 161.649C28.3544 161.619 25.9408 159.164 25.9713 156.167C26.0018 153.17 28.4649 150.764 31.4729 150.795Z"
        fill="#FFE835"
      />
      <path
        d="M98.6543 89.4077C98.6543 69.8185 114.491 53.9418 134.036 53.9418C153.581 53.9418 169.418 69.8185 169.418 89.434H98.6543V89.4077Z"
        fill="white"
      />
      <path
        d="M167.987 21.8198L167.749 76.9012L123.707 55.4953L167.458 21.5565L167.987 21.8198Z"
        fill="url(#paint7_linear_177_4368)"
      />
      <path
        d="M167.908 78.0597L121.721 56.1272L123.575 46.6486C124.369 42.6201 128.765 40.4084 132.446 42.1462L172.066 60.9455C175.747 62.6833 176.78 67.4752 174.131 70.6348L167.908 78.0334V78.0597Z"
        fill="#FFE835"
      />
      <path
        d="M98.6016 88.4862H171.802C175.139 88.4862 177.866 91.1718 177.866 94.5157C177.866 97.8332 175.165 100.545 171.802 100.545H108.559C103.077 100.545 98.6016 96.0954 98.6016 90.6452V88.5125V88.4862Z"
        fill="white"
      />
      <path
        d="M118.602 86.0639C121.833 86.0639 124.481 83.431 124.481 80.1661C124.481 76.9013 121.859 74.2683 118.602 74.2683C115.345 74.2683 112.723 76.9013 112.723 80.1661C112.723 83.431 115.345 86.0639 118.602 86.0639Z"
        fill="#0052FF"
      />
      <path
        d="M146.862 86.0639C150.093 86.0639 152.741 83.431 152.741 80.1661C152.741 76.9013 150.119 74.2683 146.862 74.2683C143.604 74.2683 140.982 76.9013 140.982 80.1661C140.982 83.431 143.604 86.0639 146.862 86.0639Z"
        fill="#0052FF"
      />
      <path
        d="M125.7 90.909C125.7 86.9332 128.905 83.7473 132.824 83.7473C136.744 83.7473 139.948 86.9595 139.948 90.909H125.674H125.7Z"
        fill="#0052FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_177_4368"
          x1="227.352"
          y1="49.6476"
          x2="261.69"
          y2="34.1956"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.263767" stopColor="#FFD200" />
          <stop offset="0.602385" stopColor="#EBCEF8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_177_4368"
          x1="100.488"
          y1="156.771"
          x2="175.888"
          y2="156.771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D17F" />
          <stop offset="0.00219411" stopColor="#02D180" />
          <stop offset="0.0894409" stopColor="#3CCE9C" />
          <stop offset="0.1787" stopColor="#70CBB5" />
          <stop offset="0.2682" stopColor="#9CC9CA" />
          <stop offset="0.3578" stopColor="#C0C7DB" />
          <stop offset="0.4475" stopColor="#DBC6E8" />
          <stop offset="0.5376" stopColor="#EFC5F1" />
          <stop offset="0.6283" stopColor="#FBC4F7" />
          <stop offset="0.7206" stopColor="#FFC4F9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_177_4368"
          x1="257.522"
          y1="103.569"
          x2="257.522"
          y2="160.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.271921" stopColor="#0F6FFF" />
          <stop offset="0.704269" stopColor="#5DE2F8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_177_4368"
          x1="257.166"
          y1="152.025"
          x2="257.166"
          y2="137.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.170635" stopColor="#F8BDF5" />
          <stop offset="0.72619" stopColor="#7FD057" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_177_4368"
          x1="14.1666"
          y1="177.644"
          x2="52.2969"
          y2="177.644"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.170635" stopColor="#F8BDF5" />
          <stop offset="0.72619" stopColor="#7FD057" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_177_4368"
          x1="335.611"
          y1="53.2787"
          x2="353.071"
          y2="39.5004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B139FF" />
          <stop offset="1" stopColor="#FF9533" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_177_4368"
          x1="340.023"
          y1="47.5325"
          x2="340.023"
          y2="56.923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0616D" />
          <stop offset="1" stopColor="#D72E1A" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_177_4368"
          x1="145.847"
          y1="38.6971"
          x2="151.22"
          y2="53.9616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F79439" />
          <stop offset="1" stopColor="#FEE735" />
        </linearGradient>
      </defs>
    </svg>
  );
}
