/* istanbul ignore file */

import { ForwardedRef, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getSelectedProducts, ProductCart } from '@slicekit/core';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { useGetOCSOnboardingLeaderboard } from 'cb-wallet-data/hooks/Gamification/useGetOCSOnboardingLeaderboard';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import Link from 'next/link';
import { useToggler } from '@cbhq/cds-common';
import { UiIconName } from '@cbhq/cds-icons';
import { Box, Grid, Spacer, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import {
  CardWithImage,
  CardWithImageCompactLoading,
  CardWithImageLoading,
} from ':dapp/components/Card/CardWithImage';
import { useColumnCountResponsive } from ':dapp/components/DynamicColumns/ColumnCountHook';
import { DynamicColumns } from ':dapp/components/DynamicColumns/DynamicColumns';
import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import {
  CarouselArrows,
  CarouselContainer,
  CarouselProvider,
} from ':dapp/components/ResponsiveCarousel/ResponsiveCarousel';
import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';
import { THE_GRAPH_KEY_INTERNAL } from ':dapp/config/env';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { useExperienceModalContext } from ':dapp/providers/ExperienceModalProvider';
import { useShareModalContext } from ':dapp/providers/ShareModalProvider';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

import { OCSActivityFeed } from '../components/OCSActivityFeed';
import { OCSCommunitiesSection } from '../components/OCSCommunitiesSection';
import { OCSCreateBanner } from '../components/OCSCreateBanner';
import { OCSExperienceOutage } from '../components/OCSExperienceOutage';
import { OCSOutage } from '../components/OCSOutage';
import { OCSPageWrapper } from '../components/OCSPageWrapper';
import { OCSSectionHeader } from '../components/OCSSectionHeader';
import { OCSSpinWheel } from '../components/OCSSpinWheel';
import { OCSSpinWheelBanner } from '../components/OCSSpinWheelBanner';
import { OCSSubscribeModal } from '../components/OCSSubscribeModal';
import { OCSGamePass } from '../components/OCSSummerPass';
import { OCSSweepstakesBanner } from '../components/OCSSweepstakesBanner';
import { LeaderboardSection } from '../components/OnboardingLeaderboard/OnboardingLeaderboard';
import { useOCSComingSoon } from '../hooks/useOCSComingSoon';
import { useOCSTrending } from '../hooks/useOCSTrending';
import { allProducts, getRandomProducts } from '../shop/allowedProducts';
import { ProductCard } from '../shop/components/ProductCard';
import { ContentComponent, OCSChallengeCard } from '../types/ocsResponseTypes';

import { TrendingCard } from './components/TrendingCard';

const messages = defineMessages({
  ocsTrendingSectionHeaderTitle: {
    defaultMessage: 'Trending onchain',
    description: "section header title for Onchain Summer's Trending onchain section",
  },
  ocsTrendingSectionHeaderSubtitle: {
    defaultMessage: 'Popular onchain experiences today',
    description: "section header subtitle for Onchain Summer's Trending onchain section",
  },
  ocsTrendingSectionLink: {
    defaultMessage: 'See experiences',
    description: 'link for trending section for onchain summer',
  },
  ocsComingSoonSectionHeaderTitle: {
    defaultMessage: 'Coming soon...',
    description: "section header title for Onchain Summer's Coming Soon section",
  },
  ocsShopOnchainTitle: {
    defaultMessage: 'Shop onchain',
    description: 'title for shop onchain section for onchain summer',
  },
  ocsShopOnchainSubtitle: {
    defaultMessage: 'Shop with USDC and redeem points for prizes as you level up',
    description: 'subtitle for shop onchain section for onchain summer',
  },
  ocsShopOnchainTag: {
    defaultMessage: 'Powered by Slice',
    description: 'tag for shop onchain section',
  },
  ocsShopOnchainLink: {
    defaultMessage: 'View all',
    description: 'link for shop onchain section for onchain summer',
  },
  ocsCommunitiesLink: {
    defaultMessage: 'View more',
    description: 'link for communities on base',
  },
  spinWheelTitle: {
    defaultMessage: 'Spin the wheel',
    description: 'Spin the wheel modal title',
  },
  getNotifiedTitle: {
    defaultMessage: 'Get notified',
    description: 'Get notified button title',
  },
});

export const getStaticProps = (async ({ params, ...rest }) => {
  let products: ProductCart[] = [];
  try {
    products = await getSelectedProducts(getConfig(), {
      products: getRandomProducts(allProducts, 3),
      dynamicPricing: true,
      thegraphApiKey: THE_GRAPH_KEY_INTERNAL,
    });
  } catch (e) {
    cbReportError({
      error: coerceError(e, 'fetch slice products on server failed'),
      context: 'slice_query',
      severity: 'error',
      isHandled: true,
    });
  }

  return {
    props: {
      ...params,
      ...rest,
      products,
    },
    revalidate: 300,
  };
}) as GetStaticProps<{
  products: ProductCart[];
}>;

function OCSTodayPage({ products }: InferGetStaticPropsType<typeof getStaticProps>) {
  const { formatMessage } = useIntl();
  const isOCSComingSoonEnabled = useIsFeatureEnabled('ocs_coming_soon');
  const { gamificationApiIssue, setExploreApiIssue, exploreApiIssue } = usePageOutageContext();
  const { data, isLoading } = useOCSTrending(setExploreApiIssue);
  const { data: comingSoonData, isLoading: isComingSoonLoading } =
    useOCSComingSoon(setExploreApiIssue);
  const { leaderboard, isLoading: isOnboardingLeaderboardLoading } =
    useGetOCSOnboardingLeaderboard();
  const [visible, { toggleOff, toggleOn }] = useToggler(false);
  const isMobile = useIsMobile();
  const communitiesEnabled = useIsFeatureEnabled('ocs_communities');
  const activityFeedEnabled = useIsFeatureEnabled('ocs_activity_feed');
  const ocsOnboardingLeaderboardEnabled = useIsFeatureEnabled('ocs_onboarding_leaderboard');
  const sweesptakesEnabled = useIsFeatureEnabled('ocs_sweepstakes');

  const trendingCards = useMemo(() => data?.contentData || [], [data]);
  const comingSoonCards = useMemo(() => comingSoonData?.contentData || [], [comingSoonData]);

  /* istanbul ignore next */
  const spinWheelModal = useMemo(() => {
    if (!visible) return null;

    return (
      <HomebaseModal
        title={formatMessage(messages.spinWheelTitle)}
        visible={visible}
        onClose={toggleOff}
        testID="spin-wheel-modal"
        contentOverflow="auto"
      >
        <OCSSpinWheel />
      </HomebaseModal>
    );
  }, [visible, toggleOff, formatMessage]);

  if (gamificationApiIssue && exploreApiIssue) {
    return <OCSOutage />;
  }

  return (
    <VStack width="100%" testID="ocs-tab-today">
      <OCSPageWrapper>
        {!gamificationApiIssue && (
          <Grid
            templateColumns={
              isMobile
                ? 'repeat(auto-fit, minmax(327px, 1fr))'
                : 'repeat(auto-fit, minmax(425px, 1fr))'
            }
            gap={4}
            spacingBottom={4}
            spacingTop={2}
            testID="ocs-gamepass-wrap"
          >
            <OCSGamePass spinWheelToggle={toggleOn} condensedView={activityFeedEnabled} />
            {activityFeedEnabled && <OCSActivityFeed />}
          </Grid>
        )}

        <OCSTrendingSection cards={trendingCards} isLoading={isLoading} />
        <Grid spacingVertical={3}>
          <OCSCreateBanner />
        </Grid>
        {isOCSComingSoonEnabled && (
          <OCSComingSoonSection cards={comingSoonCards} isLoading={isComingSoonLoading} />
        )}

        <OCSShopOnChainSection products={products} />
        {ocsOnboardingLeaderboardEnabled && (
          <LeaderboardSection
            leaderboard={leaderboard}
            isLoading={isOnboardingLeaderboardLoading}
          />
        )}
        <Spacer vertical={4} />
        {communitiesEnabled && <OCSCommunitiesSection />}
        <Grid templateColumns="repeat(auto-fit, minmax(327px, 1fr))" gap={4}>
          {sweesptakesEnabled && <OCSSweepstakesBanner />}
        </Grid>
        {spinWheelModal}
        <Grid
          templateColumns="repeat(auto-fit, minmax(327px, 1fr))"
          spacingBottom={4}
          spacingTop={4}
        >
          <OCSSpinWheelBanner toggleOn={toggleOn} />
        </Grid>
      </OCSPageWrapper>
    </VStack>
  );
}

type TrendingSectionProps = {
  cards: ContentComponent[];
  isLoading: boolean;
};

function OCSTrendingSection(props: TrendingSectionProps) {
  const { formatMessage } = useIntl();
  const { openExperienceModal } = useExperienceModalContext();
  const { openShareModal } = useShareModalContext();
  const { gamificationApiIssue, exploreApiIssue } = usePageOutageContext();

  const handleClick = useCallback(
    (content: OCSChallengeCard) => () => {
      openExperienceModal(content);
    },
    [openExperienceModal],
  );

  const handleShareClick = useCallback(
    (challenge: OCSChallengeCard) => (event: React.MouseEvent) => {
      event.stopPropagation();
      openShareModal(challenge);
    },
    [openShareModal],
  );

  if (props.cards.length === 0) {
    return null;
  }

  return (
    <>
      <OCSSectionHeader
        title={formatMessage(messages.ocsTrendingSectionHeaderTitle)}
        subtitle={formatMessage(messages.ocsTrendingSectionHeaderSubtitle)}
        spacingBottom={4}
        rightContent={
          <Link href={RoutesEnum.OCS_PLAY}>
            <TextBody as="span" color="primary" noWrap>
              {formatMessage(messages.ocsTrendingSectionLink)}
            </TextBody>
          </Link>
        }
      />
      {exploreApiIssue ? (
        <OCSExperienceOutage />
      ) : (
        <Grid templateColumns="repeat(auto-fit, minmax(327px, 1fr))" gap={4} spacingBottom={4}>
          {props.isLoading && !props.cards?.length
            ? Array(6)
                .fill(0)
                .map((_) => <CardWithImageLoading />)
            : props.cards.map((card) => {
                const challengeCard = { ...card.ocsChallengeCard, id: card.id };

                return (
                  <TrendingCard
                    card={card}
                    key={`trending-${card.id}`}
                    onClick={handleClick(challengeCard)}
                    isLoading={props.isLoading}
                    shareClick={handleShareClick(challengeCard)}
                    gamificationError={gamificationApiIssue}
                  />
                );
              })}
        </Grid>
      )}
    </>
  );
}

type OCSComingSoonSectionProps = {
  cards: ContentComponent[];
  isLoading: boolean;
};

function OCSComingSoonSection(props: OCSComingSoonSectionProps) {
  const { formatMessage } = useIntl();
  const { openShareModal } = useShareModalContext();
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const innerHeightTrackerRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (toggle: boolean) => () => {
      setOpenSubscribeModal(toggle);
    },
    [setOpenSubscribeModal],
  );

  const handleShareClick = useCallback(
    (challenge: OCSChallengeCard) => (event: React.MouseEvent) => {
      event.stopPropagation();
      openShareModal(challenge);
    },
    [openShareModal],
  );

  if (props.cards.length === 0) {
    return null;
  }

  return (
    <VStack maxWidth="100%" spacingBottom={4}>
      <CarouselProvider>
        <OCSSectionHeader
          title={formatMessage(messages.ocsComingSoonSectionHeaderTitle)}
          spacingVertical={4}
          rightContent={<CarouselArrows />}
        />
        <CarouselContainer watchedHeightRef={innerHeightTrackerRef}>
          {props.isLoading && !props.cards?.length
            ? [1, 2, 3].map((n) => <CardWithImageCompactLoading key={n} />)
            : props.cards.map((card) => {
                return (
                  <ComingSoonCard
                    card={card}
                    key={`coming-soon-${card.id}`}
                    onClick={handleClick(true)}
                    shareClick={handleShareClick({ ...card.ocsChallengeCard, id: card.id })}
                    isLoading={props.isLoading}
                    ref={innerHeightTrackerRef}
                  />
                );
              })}
        </CarouselContainer>
      </CarouselProvider>
      {openSubscribeModal && <OCSSubscribeModal handleClose={handleClick(false)} />}
    </VStack>
  );
}

type ComingSoonCardProps = {
  card: ContentComponent;
  isLoading: boolean;
  onClick: () => void;
  shareClick: (event: React.MouseEvent) => void;
};

const ComingSoonCard = forwardRef(function ComingSoonCard(
  props: ComingSoonCardProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const content = props.card.ocsChallengeCard;
  const { formatMessage } = useIntl();
  const challengeBadgesEnabled = useIsFeatureEnabled('ocs_challenge_badges');

  const image = useMemo(() => ({ src: content.imageUrl }), [content]);
  const badge = useMemo(
    () =>
      challengeBadgesEnabled
        ? {
            numCompletions: 0,
            launchDate: `🕕 LAUNCHES ${new Date(props.card.startTs).toLocaleString('en-US', {
              month: 'short',
              day: '2-digit',
            })}`,
          }
        : undefined,
    [props.card, challengeBadgesEnabled],
  );

  const action = useMemo(
    () => ({
      text: formatMessage(messages.getNotifiedTitle),
      icon: 'bell' as UiIconName,
      url: '',
    }),
    [formatMessage],
  );

  return (
    <CardWithImage
      isLoading={props.isLoading}
      title={content.title}
      image={image}
      badge={badge}
      key={content.title}
      shareClick={props.shareClick}
      action={action}
      onClick={props.onClick}
      ref={ref}
    />
  );
});

type OCSShopOnChainSectionProps = {
  products: ProductCart[];
};

function OCSShopOnChainSection({ products }: OCSShopOnChainSectionProps) {
  const { formatMessage } = useIntl();
  const shopIsEnabled = useIsFeatureEnabled('ocs_shop_tab');
  const columnCount = useColumnCountResponsive();

  if (!products?.length) {
    return null;
  }

  if (shopIsEnabled) {
    return (
      <>
        <OCSSectionHeader
          title={formatMessage(messages.ocsShopOnchainTitle)}
          subtitle={formatMessage(messages.ocsShopOnchainSubtitle)}
          tag={formatMessage(messages.ocsShopOnchainTag)}
          spacingVertical={4}
          rightContent={
            <Link href={RoutesEnum.OCS_PRODUCTS}>
              <TextBody as="span" color="primary" noWrap>
                {formatMessage(messages.ocsShopOnchainLink)}
              </TextBody>
            </Link>
          }
        />
        <DynamicColumns>
          {products.slice(0, columnCount).map((product) => (
            <ProductCard product={product} key={`ocs-today-tab-product-cards-${product.dbId}`} />
          ))}
        </DynamicColumns>
        <Box height={32} />
      </>
    );
  }
  return null;
}

export default withFeatureEnabled(OCSTodayPage, 'ocs_today_tab');
