import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { OCSActivity } from 'cb-wallet-data/hooks/Gamification/useGetOCSActivity';
import { PublicProfile } from 'cb-wallet-data/stores/DecentralizedID/types';
import { getTruncatedAddress } from 'cb-wallet-data/utils/getTruncatedAddress';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { TextBody, TextLabel1 } from '@cbhq/cds-web/typography';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { OCSAvatar } from './OCSAvatar';
type OCSActivityItemProp = {
  name?: string;
  userAddress: string;
  userProfile?: PublicProfile;
  activityData: OCSActivity;
};
const messages = defineMessages({
  completedChallengeActivity: {
    defaultMessage: '{displayName} completed a challenge',
    description: 'Activity item for a completed challenge'
  },
  spinWheelActivity: {
    defaultMessage: '{displayName} spun the wheel',
    description: 'Activity item for a spin the wheel'
  },
  badgeActivity: {
    defaultMessage: '{displayName} claimed {badgeName} badge',
    description: 'Activity item for a badge'
  },
  referralActivity: {
    defaultMessage: '{displayName} joined via a referral',
    description: 'Activity item for a referral'
  },
  generalActivity: {
    defaultMessage: '{displayName} used ocs',
    description: 'Activity item for general activity'
  },
  justNow: {
    defaultMessage: 'just now',
    description: 'Text for just now'
  }
});
const activityTextClass = "aypl9vh";
const badgeIdToAbbreviation: Record<number, string> = {
  1: 'SWC',
  2: 'CB1',
  3: 'Builder'.toUpperCase(),
  4: 'Collector'.toUpperCase(),
  5: 'Trader'.toUpperCase(),
  6: 'Saver'.toUpperCase(),
  7: 'Based'.toUpperCase(),
  8: 'Based'.toUpperCase(),
  9: 'Based'.toUpperCase(),
  10: 'Based'.toUpperCase()
};
export function OCSActivityItem({
  name,
  userAddress,
  userProfile,
  activityData
}: OCSActivityItemProp) {
  const isMobile = useIsMobile();
  const {
    formatMessage
  } = useIntl();
  const displayName = name || getTruncatedAddress(userAddress);
  const defaultUserProfile = useMemo((): PublicProfile => ({
    primaryDomainType: 'cbid',
    address: userAddress
  }), [userAddress]);
  const timeAgo = useCallback((inputDate: string) => {
    const now = new Date();
    const date = new Date(inputDate);
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
    };
    let counter: number;
    for (const [unit, value] of Object.entries(intervals)) {
      counter = Math.floor(diffInSeconds / value);
      if (counter > 0) {
        return `${counter}${unit[0]}`;
      }
    }

    /* istanbul ignore next */
    return formatMessage(messages.justNow);
  }, [formatMessage]);
  const activityText = useMemo(() => {
    switch (activityData.type) {
      case 'challenge':
        return formatMessage(messages.completedChallengeActivity, {
          displayName
        });
      case 'spin':
        return formatMessage(messages.spinWheelActivity, {
          displayName
        });
      case 'badge':
        return formatMessage(messages.badgeActivity, {
          displayName,
          badgeName: badgeIdToAbbreviation[activityData.data?.badgeId || 1]
        });
      case 'referral':
        return formatMessage(messages.referralActivity, {
          displayName
        });
      default:
        return formatMessage(messages.generalActivity, {
          displayName
        });
    }
  }, [activityData, displayName, formatMessage]);
  return <Box borderRadius="roundedMedium" spacingVertical={1} spacingHorizontal={3} spacingBottom={1} spacingTop={2}>
      <HStack width="100%" alignItems="center">
        <HStack gap={2}>
          <OCSAvatar publicProfile={userProfile ?? defaultUserProfile} width={40} height={40} shape="circle" />
        </HStack>
        <HStack spacingHorizontal={2} maxWidth={isMobile ? '75%' : '85%'}>
          <TextBody mono as="span" noWrap overflow="truncate" className={activityTextClass}>
            {activityText}
          </TextBody>
        </HStack>
        <Box width="100%" justifyContent="flex-end">
          <TextLabel1 mono as="span" color="foregroundMuted" overflow="truncate">
            {timeAgo(activityData.createdAt)}
          </TextLabel1>
        </Box>
      </HStack>
    </Box>;
}

require("./OCSActivityItem.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSActivityItem.tsx");