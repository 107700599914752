type OCSBitcoinGlobeProps = {
  width?: number;
  height?: number;
};

export function OCSBitcoinGlobe({ width = 221, height = 217 }: OCSBitcoinGlobeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 221 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110 179.539C158.601 179.539 198 140.14 198 91.5391C198 42.938 158.601 3.53906 110 3.53906C61.3989 3.53906 22 42.938 22 91.5391C22 140.14 61.3989 179.539 110 179.539Z"
        fill="#464B55"
      />
      <path d="M143.917 93.375H76.0833V203.375H143.917V93.375Z" fill="#050607" />
      <path
        d="M110 139.201C119.423 139.201 128.645 136.405 136.483 131.171C144.32 125.936 150.434 118.484 154.037 109.776C157.648 101.067 158.593 91.4788 156.75 82.2388C154.908 72.9897 150.37 64.5013 143.706 57.8372C137.042 51.173 128.544 46.6355 119.304 44.793C110.055 42.9505 100.476 43.8947 91.7675 47.5063C83.0592 51.118 75.6158 57.223 70.3725 65.0605C65.1383 72.898 62.3425 82.1105 62.3425 91.543C62.3425 104.184 67.3658 116.311 76.3033 125.249C85.2408 134.186 97.3684 139.21 110.009 139.21L110 139.201Z"
        fill="url(#paint0_linear_11250_155563)"
      />
      <path
        d="M168.667 216.206H51.3333L58.6666 179.539H161.333L168.667 216.206Z"
        fill="url(#paint1_linear_11250_155563)"
      />
      <path
        d="M44.0001 84.2031C42.7809 87.6131 40.0767 90.3173 36.6667 91.5365C40.0767 92.7556 42.7809 95.4598 44.0001 98.8698C45.2192 95.4598 47.9234 92.7556 51.3334 91.5365C47.9234 90.3173 45.2192 87.6131 44.0001 84.2031Z"
        fill="white"
      />
      <path
        d="M176 161.208C178.438 154.379 183.837 148.98 190.667 146.542C183.837 144.103 178.438 138.704 176 131.875C173.562 138.704 168.162 144.103 161.333 146.542C168.162 148.98 173.562 154.379 176 161.208Z"
        fill="#464B55"
      />
      <path
        d="M62.3334 47.5391C62.3334 47.5391 62.5901 64.1583 77.0001 69.5758V69.5115C62.5901 74.929 62.3334 91.5482 62.3334 91.5482C62.3334 91.5482 62.0767 74.929 47.6667 69.5115V69.5758C62.0767 64.1583 62.3334 47.5391 62.3334 47.5391Z"
        fill="white"
      />
      <path
        d="M44 21.875C42.1758 26.99 38.115 31.0508 33 32.875C38.115 34.6992 42.1758 38.76 44 43.875C45.8242 38.76 49.885 34.6992 55 32.875C49.885 31.0508 45.8242 26.99 44 21.875Z"
        fill="#464B55"
      />
      <path
        d="M168.667 172.203H51.3333C47.2832 172.203 44 175.486 44 179.536C44 183.587 47.2832 186.87 51.3333 186.87H168.667C172.717 186.87 176 183.587 176 179.536C176 175.486 172.717 172.203 168.667 172.203Z"
        fill="url(#paint2_linear_11250_155563)"
      />
      <path
        d="M51.3334 201.539H168.667C176.761 201.539 183.333 208.112 183.333 216.206H36.6667C36.6667 208.112 43.2392 201.539 51.3334 201.539Z"
        fill="url(#paint3_linear_11250_155563)"
      />
      <path
        d="M182.417 141.546C179.318 146.028 175.817 150.208 171.948 154.04C169.189 150.658 165.532 148.036 161.333 146.542C168.162 144.103 173.562 138.695 176 131.875C177.338 135.606 179.557 138.924 182.417 141.546Z"
        fill="url(#paint4_linear_11250_155563)"
      />
      <path
        d="M48.3909 28.7188C50.2517 30.5521 52.4975 31.9729 55 32.8713C49.885 34.6954 45.8242 38.7471 44 43.8713C43.1842 41.5888 41.9284 39.5171 40.3425 37.7662L48.3909 28.7188V28.7188Z"
        fill="url(#paint5_linear_11250_155563)"
      />
      <path d="M168.667 216.206H51.3333L54.2666 201.539H165.733L168.667 216.206Z" fill="#050607" />
      <path
        d="M96.5527 79.441C98.3036 79.441 98.8627 80.4492 99.0186 81.3201V89.3317C99.1377 89.3317 99.3027 89.3318 99.4861 89.3684H99.0186V100.588C98.9452 101.129 98.6244 102.009 97.4052 102.009C97.4602 102.064 93.3994 102.009 93.3994 102.009L93.4361 107.124H99.0277C100.064 107.124 101.09 107.143 102.099 107.161V114.274H106.389V107.234C107.58 107.253 108.717 107.271 109.826 107.271V114.274H114.125V107.17C121.349 106.758 126.409 104.924 127.041 98.1776C127.554 92.7326 124.969 90.3034 120.872 89.3317C123.365 88.0667 124.914 85.8392 124.557 82.1176C124.071 77.0576 119.671 75.3526 114.125 74.8668V67.8359H109.826V74.6742C108.699 74.6742 107.544 74.6926 106.389 74.7293V67.8451H102.099V74.876C101.164 74.8943 100.247 74.9126 99.3577 74.9126V74.8942H93.4361V79.4684C93.4361 79.4684 96.6261 79.386 96.5711 79.4501L96.5527 79.441ZM106.489 79.7618C108.919 79.7618 116.536 78.9918 116.536 84.0335C116.536 89.0751 108.919 88.3051 106.489 88.3051V79.7618V79.7618ZM106.489 92.6318C109.404 92.6318 118.534 91.7884 118.534 97.3434C118.534 102.66 109.395 102.055 106.489 102.055V92.641V92.6318Z"
        fill="#0A0B0D"
      />
      <path
        d="M110.101 53.1007C131.294 53.1007 148.537 70.3431 148.537 91.5365C148.537 112.73 131.294 129.972 110.101 129.972C88.9073 129.972 71.6648 112.73 71.6648 91.5365C71.6648 70.3431 88.9073 53.1007 110.101 53.1007ZM110.101 51.2031C87.8257 51.2031 69.7673 69.2615 69.7673 91.5365C69.7673 113.811 87.8257 131.87 110.101 131.87C132.376 131.87 150.434 113.811 150.434 91.5365C150.434 69.2615 132.376 51.2031 110.101 51.2031Z"
        fill="#0A0B0D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11250_155563"
          x1={62.3425}
          y1={91.5423}
          x2={157.668}
          y2={91.5423}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9533" />
          <stop offset={1} stopColor="#FFE835" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11250_155563"
          x1={51.3333}
          y1={197.872}
          x2={168.667}
          y2={197.872}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45E1E5" />
          <stop offset={0.880435} stopColor="#0052FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11250_155563"
          x1={44}
          y1={179.536}
          x2={176}
          y2={179.536}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45E1E5" />
          <stop offset={0.880435} stopColor="#0052FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11250_155563"
          x1={36.6667}
          y1={208.872}
          x2={183.333}
          y2={208.872}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45E1E5" />
          <stop offset={0.880435} stopColor="#0052FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11250_155563"
          x1={161.333}
          y1={142.958}
          x2={182.417}
          y2={142.958}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45E1E5" />
          <stop offset={0.880435} stopColor="#0052FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11250_155563"
          x1={40.3425}
          y1={36.295}
          x2={55}
          y2={36.295}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45E1E5" />
          <stop offset={0.880435} stopColor="#0052FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
