import { defineMessages, useIntl } from 'react-intl';
import Image from 'next/image';
import { Group, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

const messages = defineMessages({
  experienceOutageTitle: {
    defaultMessage: 'Oh no! Experiences aren’t available right now',
    description: 'Experiences outage title',
  },
  experienceOutageSubtitle: {
    defaultMessage: 'We’re working on it. Please try again later.',
    description: 'Experiences outage subtitle',
  },
});

export function OCSExperienceOutage() {
  const { formatMessage } = useIntl();

  return (
    <VStack alignItems="center" spacingVertical={4} gap={3} testID="ocs-experience-outage">
      <Image
        alt="Experiences not available"
        src="https://go.wallet.coinbase.com/static/catLostSystemError.png"
        width={240}
        height={240}
      />
      <Group gap={1} alignItems="center">
        <TextTitle3 as="div" align="center">
          {formatMessage(messages.experienceOutageTitle)}
        </TextTitle3>
        <TextBody as="p" color="foregroundMuted" spacingHorizontal={0}>
          {formatMessage(messages.experienceOutageSubtitle)}
        </TextBody>
      </Group>
    </VStack>
  );
}
