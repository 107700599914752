import { CSSProperties, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'wallet-cds-web/components/Button';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Grid, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { TextLabel2, TextTitle1 } from '@cbhq/cds-web/typography';

import { useIsMobile } from ':dapp/hooks/useIsMobile';

import { OCSCreateIcon } from './OCSCreateIcon';

const messages = defineMessages({
  title: {
    defaultMessage: 'Create, share, earn',
    description: 'title',
  },
  subtitle: {
    defaultMessage: 'Put your creativity onchain, share with the world, and start earning',
    description: 'subtitle',
  },
  button: {
    defaultMessage: 'Create now',
    description: 'call to action',
  },
});

const gradientStyle: CSSProperties = {
  background: 'linear-gradient(to right, #7FD057, #45E1E5)', // Change colors as needed
};

export function OCSCreateBanner() {
  const { isPhone } = useBreakpoints();
  const isMobile = useIsMobile();
  const { formatMessage } = useIntl();

  const infoDetails = useMemo(
    () => (
      <Grid columns={isPhone ? 1 : 2} maxWidth={1000} testID="banner-cols">
        <VStack
          gap={1}
          spacing={3}
          id="create-banner"
          position="relative"
          zIndex={zIndex.interactable}
        >
          <TextTitle1 as="h3" color="secondary">
            {formatMessage(messages.title)}
          </TextTitle1>
          <ThemeProvider spectrum="light">
            <TextLabel2 as="p" color="foregroundMuted">
              {formatMessage(messages.subtitle)}
            </TextLabel2>
          </ThemeProvider>
          <Spacer vertical={1} />
          <Box width="200px" zIndex={1}>
            <ThemeProvider spectrum="dark">
              <Button
                variant="secondary"
                testID="createBtn"
                href="https://wallet.coinbase.com/assets/nft/create"
                compact
              >
                {formatMessage(messages.button)}
              </Button>
            </ThemeProvider>
          </Box>
        </VStack>
        <HStack alignContent="center">
          <Box position="relative" height={0} />
          {!isPhone &&
            (!isMobile ? (
              <Box position="absolute" right={0}>
                <Box position="relative" width={400}>
                  <OCSCreateIcon />
                </Box>
              </Box>
            ) : (
              <Box position="absolute" left={300} width={400}>
                <OCSCreateIcon />
              </Box>
            ))}
        </HStack>
      </Grid>
    ),
    [formatMessage, isMobile, isPhone],
  );

  return (
    <>
      {!isPhone ? (
        <HStack
          style={gradientStyle}
          background="positive"
          overflow="hidden"
          position="sticky"
          borderRadius="roundedLarge"
        >
          {infoDetails}
        </HStack>
      ) : (
        <VStack
          style={gradientStyle}
          overflow="hidden"
          gap={3}
          borderRadius="roundedLarge"
          alignContent="center"
          justifyContent="center"
          alignSelf="center"
        >
          {infoDetails}
          <Box position="relative" height={175} alignSelf="center">
            <Box position="absolute" top={-20} left={-195} width={390}>
              <OCSCreateIcon />
            </Box>
          </Box>
        </VStack>
      )}
    </>
  );
}
